<template>
  <div style="color: #000" class="box my_warp">
    <h2 style="text-align: center; font-size: 17px; padding: 15px 0">
      {{COMPANY_NAME_All}}用户支付协议
    </h2>
    &emsp;&emsp;感谢您使用【{{COMPANY_NAME_All}}直播网站】会员增值服务，为使用【{{COMPANY_NAME_All}}直播网站】会员增值服务（简称为：本服务），您应当阅读并遵守《{{COMPANY_NAME_All}}直播网站会员服务协议》（简称为：本协议）。请您务必审慎阅读、充分理解各条款内容，特别是免除或限制责任等相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。免除或限制责任条款可能以加粗形式提示您注意。
    未成年人应在法定监护人陪同下审阅和履行本协议，未成年人行使和履行本协议项下的权利和义务视为已获得法定监护人的认可。<br />
    一、协议的范围 <br />
    &emsp;&emsp;1.1<br />
    &emsp;&emsp;协议适用主体范围本协议是您与{{COMPANY_NAME_All}}之间关于您使用本增值服务所订立的协议。
    <br />
    &emsp;&emsp;1.2<br />
    &emsp;&emsp;您同意本协议即视为您自愿接受并遵守{{COMPANY_NAME_All}}直播网站提供服务涉及的包括本协议在内的所有服务规范及相关协议、管理规范，包括但不限于{{COMPANY_NAME_All}}直播网站不时发布并修订的关于{{COMPANY_NAME_All}}直播网站服务协议、隐私政策、会员相关协议、活动规则、使用规则、公告、提示、通知等内容。<br />
    二、服务内容 <br />
    &emsp;&emsp;2.1<br />
    &emsp;&emsp;会员增值服务，指您按照{{COMPANY_NAME_All}}直播网站的指定方式支付一定服务费用之后，可以享有由{{COMPANY_NAME_All}}直播网站提供的的特权服务，简称为：本服务。<br />
    &emsp;&emsp;2.2<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播网站可能会根据不同的产品及服务类型，推出不同的会员增值服务，对现有会员增值服务种类、会员权益等进行调整以及不断推出新的会员增值服务种类。具体会员增值服务种类及服务内容以相关服务页面公布、实际提供的服务内容为准，并在公告后依法生效。您可以自行根据需要选择相应服务。<br />
    &emsp;&emsp;2.3<br />
    &emsp;&emsp;关于广告的特别说明：您在使用{{COMPANY_NAME_All}}直播网站会员服务的过程中，将接触到以各种方式投放的商业性广告或其他类型的商业信息，{{COMPANY_NAME_All}}直播网站会提供“免广告特权”,“免广告特权”的范围仅指在{{COMPANY_NAME_All}}直播网站中带有“广告”标识的内容，不含第三方信息资讯等内容。广告特权包括为您自动跳过广告，也可手动关闭内容推荐；但此权益可能因版权方限制或其他限制原因，仍可能会向您呈现不同种类的广告服务，且亦不排除{{COMPANY_NAME_All}}直播网站以其他方式投放的广告或商业信息。对此您理解并予以接受，您不追究并豁免{{COMPANY_NAME_All}}直播网站的相关责任，同时{{COMPANY_NAME_All}}直播网站承诺会尽量降低对您的使用体验影响。<br />
    三、会员费用及期限 <br />
    &emsp;&emsp;3.1 费用标准及支付 <br />
    &emsp;&emsp;&emsp;&emsp;3.1.1<br />
    &emsp;&emsp;&emsp;&emsp;本服务是{{COMPANY_NAME_All}}直播网站提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。<br />
    &emsp;&emsp;&emsp;&emsp;3.1.2<br />
    &emsp;&emsp;&emsp;&emsp;{{COMPANY_NAME_All}}直播网站可能会根据本服务的整体规划，对本服务的收费标准、方式等进行修改和变更，前述修改、变更，{{COMPANY_NAME_All}}直播网站将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前了解清楚当时关于本服务的收费标准、方式等信息。您的开通以及支付行为视为您完全清楚{{COMPANY_NAME_All}}直播网站提供收费服务的收费标准以及方式。<br />
    &emsp;&emsp;&emsp;&emsp;3.1.3 <br />
    &emsp;&emsp;&emsp;&emsp;您可以通过{{COMPANY_NAME_All}}直播网站指定的包括但不限于微信支付、支付宝、Apple、Pay等现有方式、今后{{COMPANY_NAME_All}}直播网站指定方式在依约支付费用后开通本服务。 <br />
    &emsp;&emsp;&emsp;&emsp;3.1.4<br />
    &emsp;&emsp;&emsp;&emsp;{{COMPANY_NAME_All}}直播网站在此声明，您支付后{{COMPANY_NAME_All}}直播网站不接受任何形式的退费请求，除非因{{COMPANY_NAME_All}}直播网站产品技术原因，导致您购买时被重复扣款或因{{COMPANY_NAME_All}}直播网站产品技术原因导致您无法享有购买的服务时，您可提出退款申诉，经过{{COMPANY_NAME_All}}直播网站核实后可为您办理退款，但您需要提供相应信息并配合{{COMPANY_NAME_All}}直播网站处理。您理解并清楚接受上述条件，如您不接受的，请谨慎选择收费服务。<br />
    &emsp;&emsp;&emsp;&emsp;3.2 服务期限 <br />
    &emsp;&emsp;&emsp;&emsp;3.2.1<br />
    &emsp;&emsp;&emsp;&emsp;本服务的服务期限以您自行选择并支付相应服务费用的期限为准，本服务现有包月、包季、包年服务，您也可以登录{{COMPANY_NAME_All}}直播网站个人中心或者本服务的相应页面查询。会员服务的服务期限到期后，{{COMPANY_NAME_All}}直播网站将停止继续向您提供会员服务，服务期限届满前{{COMPANY_NAME_All}}直播网站可能会提醒您及时续费。<br />
    &emsp;&emsp;&emsp;&emsp;3.2.2<br />
    &emsp;&emsp;&emsp;&emsp;如您希望享受会员服务连续采购和自动扣费的服务，您可以根据操作指引向{{COMPANY_NAME_All}}直播网站提出申请，并在仔细阅读《{{COMPANY_NAME_All}}直播网站会员自动续费服务协议》后决定是否继续申请。<br />
    四、会员服务的开通、账号管理及注销 4.1<br />
    &emsp;&emsp;您通过{{COMPANY_NAME_All}}直播网站指定的方式依约支付费用后即开通本服务。本服务开通之后，仅限于您在{{COMPANY_NAME_All}}直播网站平台使用，不及于其他任何平台，未经{{COMPANY_NAME_All}}直播网站书面同意不可提供给任何第三方进行使用，包括但不限于赠与、出售、租用、借用、分享、受让等方式。<br />
    &emsp;&emsp;4.2 会员账号的管理<br />
    &emsp;&emsp;（1）您充分了解并同意，您须为自己登录账号下的行为负责，本账号下的一切行为均视为您本人的行为，包括但不限于您所发表的任何内容以及由此产生的后果，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。您应对本服务提供的内容自行加以判断，并承担因使用相关内容而引起的风险。<br />
    &emsp;&emsp;（2）您应正确使用及妥善保管、维护您的会员账号及密码，如发生泄露、遗失、被盗等行为，而该等行为并非{{COMPANY_NAME_All}}直播网站法定过错导致，损失将由您自行承担。<br />
    &emsp;&emsp;（3）会员服务仅限于申请账号自行使用；每个账号之间的充值记录、数据无法进行转移、迁徙、转让、赠与、售卖、租借、分享，无论该等账号是否由同一使用人拥有，请您在登录及/或参加活动时注意区分，避免造成损失。<br />
    &emsp;&emsp;4.3 账号的使用 您不得通过以下任何方式为自己或他人开通本服务：<br />
    &emsp;&emsp;（1）以营利、经营等非个人使用的目的为自己或他人开通本服务；<br />
    &emsp;&emsp;（2）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式为自己或他人开通本服务；<br />
    &emsp;&emsp;（3）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通本服务；<br />
    &emsp;&emsp;（4）通过非{{COMPANY_NAME_All}}直播网站指定的方式开通本服务；<br />
    &emsp;&emsp;（5）通过侵犯{{COMPANY_NAME_All}}直播网站或他人合法权益的方式开通本服务；<br />
    &emsp;&emsp;（6）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。<br />
    &emsp;&emsp;您理解并同意：若您存在以上行为{{COMPANY_NAME_All}}直播网站有权采取相应措施（包括但不限于未经通知即关闭服务、要求您赔偿{{COMPANY_NAME_All}}直播网站因此遭受的全部损失以及提请司法机关追究法律责任）追究您的相关责任。<br />
    &emsp;&emsp;4.4<br />
    &emsp;&emsp;会员账号注销：除{{COMPANY_NAME_All}}直播网站按照协议或公告及相关法律法规注销用户的会员账号外，您可以通过在线申请或联系{{COMPANY_NAME_All}}直播网站的客服等方式注销会员账号。您确认账号一旦注销，您将无法继续使用会员服务。且您曾通过该账号使用相关服务所涉及的所有内容、信息、数据、记录将会被删除或匿名化处理；除法律法规另有规定，您也无法再检索、访问、获取、继续使用和找回前述内容、信息、数据、记录，也无权要求{{COMPANY_NAME_All}}直播网站找回。<br />
    五、会员行为规范 <br />
    &emsp;&emsp;5.1<br />
    &emsp;&emsp;您在使用本服务时不得利用本服务从事以下行为，包括但不限于：<br />
    &emsp;&emsp;（1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；<br />
    &emsp;&emsp;（2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；<br />
    &emsp;&emsp;（3）虚构事实、隐瞒真相以误导、欺骗他人；<br />
    &emsp;&emsp;（4）发表、传送、传播广告信息及垃圾信息；<br />
    &emsp;&emsp;（5）从事其他违反法律法规（包括但不限于《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《信息网络传播权保护条例》、《网络安全法》等有关计算机及互联网规定的法律、法规）、政策及公序良俗、社会公德等的行为。<br />
    &emsp;&emsp;5.2 本服务仅供您个人使用，除非经{{COMPANY_NAME_All}}直播网站书面许可，您不得进行以下行为：<br />
    &emsp;&emsp;（1）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；<br />
    &emsp;&emsp;（2）通过本服务发布包含广告、宣传、促销等内容的信息；<br />
    &emsp;&emsp;（3）将本服务再次许可他人使用； （4）其他未经{{COMPANY_NAME_All}}直播网站书面许可的行为。<br />
    &emsp;&emsp;5.3 服务的变更、中止或终止 <br />
    &emsp;&emsp;&emsp;&emsp;5.3.1 本服务的中止或终止包含如下情况：<br />
    &emsp;&emsp;&emsp;&emsp;（1）您主动中止或终止，包括但不限于会员到期未进行续费等；<br />
    &emsp;&emsp;&emsp;&emsp;（2）因为您的违约行为，{{COMPANY_NAME_All}}直播网站主动中止或终止服务的；<br />
    &emsp;&emsp;&emsp;&emsp;（3）因国家或相关政府监管部门要求或发生不可抗力事件时，{{COMPANY_NAME_All}}直播网站中止或终止服务的；<br />
    &emsp;&emsp;&emsp;&emsp;（4）其他根据法律法规应中止或终止服务的。 5.3.2<br />
    &emsp;&emsp;&emsp;&emsp;您充分了解并同意，由于互联网服务的特殊性，{{COMPANY_NAME_All}}直播网站可能会按照相关法律法规、双方约定或在其他必要时，修改、变更、中止或终止向您提供部分或全部服务，届时，{{COMPANY_NAME_All}}直播网站会依法保护您的合法权益。且您同意，依本服务协议规定提供之服务，无需进行事先通知即可变更、中止或终止，针对变更服务，如果您继续使用，即视为您同意该变更。本条记载的情形下不视为{{COMPANY_NAME_All}}直播网站违约，{{COMPANY_NAME_All}}直播网站无需向您承担任何法律责任。<br />
    &emsp;&emsp;&emsp;&emsp;5.3.3<br />
    &emsp;&emsp;&emsp;&emsp;{{COMPANY_NAME_All}}直播网站未及时行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥其任何其他权利的行使。<br />
    六、违约责任 <br />
    &emsp;&emsp;6.1 违约的处理<br />
    &emsp;&emsp;如果{{COMPANY_NAME_All}}直播网站发现或收到他人举报您有任何违反本协议的行为，{{COMPANY_NAME_All}}直播网站有权依法进行独立判断并采取技术手段予以删除、屏蔽或断开相关的信息。同时，{{COMPANY_NAME_All}}直播网站有权视您的行为性质，对您采取包括但不限于中止或终止部分或全部本服务、中止或终止您对{{COMPANY_NAME_All}}直播网站会员使用、追究您法律责任等措施，{{COMPANY_NAME_All}}直播网站也无需向您退还任何费用，而由此给您带来的损失，由您自行承担，造成{{COMPANY_NAME_All}}直播网站损失的，您也应予以赔偿（包括但不限于直接经济损失、商誉损失及对外支付的赔偿金、和解费、律师费、诉讼费等合理费用），并且在{{COMPANY_NAME_All}}直播网站要求的时限内支付完毕。<br />
    &emsp;&emsp;6.2 对第三方损害的处理<br />
    &emsp;&emsp;您有违反本协议约定的任何行为，导致任何第三方损害的，您应当独立承担责任；{{COMPANY_NAME_All}}直播网站因此遭受损失的，您也应当进行赔偿。<br />
    七、其他 <br />
    &emsp;&emsp;7.1 协议的生效<br />
    &emsp;&emsp;您使用本服务即视为您已阅读并同意受本协议的约束。除本协议另有约定情况外，本协议自您选择接受或使用本服务后生效，直至终止本服务时止。<br />
    &emsp;&emsp;7.2 适用法律<br />
    &emsp;&emsp;本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。<br />
    &emsp;&emsp;7.3 争议解决<br />
    &emsp;&emsp;若您和【{{COMPANY_NAME_All}}直播网站】之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交有管辖权的人民法院管辖。<br />
    &emsp;&emsp;7.4 条款效力<br />
    &emsp;&emsp;本协议条款无论因何种原因部分无效或不可执行，不影响其余条款的效力及执行，对双方具有约束力。<br />
  </div>
</template>

<script>
export default {
  data(){
    return{
      COMPANY_NAME_All:process.env.VUE_APP_COMPANY_NAME_All,
      COMPANY_NAME_ABB:process.env.VUE_APP_COMPANY_NAME_ABB,
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 12px;
}
</style>